/* eslint-disable camelcase */
export const getUSStates = () => {
    return [
        { name: 'Alabama', abbreviation: 'AL' },
        { name: 'Alaska', abbreviation: 'AK' },
        { name: 'American Samoa', abbreviation: 'AS' },
        { name: 'Arizona', abbreviation: 'AZ' },
        { name: 'Arkansas', abbreviation: 'AR' },
        { name: 'California', abbreviation: 'CA' },
        { name: 'Colorado', abbreviation: 'CO' },
        { name: 'Connecticut', abbreviation: 'CT' },
        { name: 'Delaware', abbreviation: 'DE' },
        { name: 'District of Columbia', abbreviation: 'DC' },
        { name: 'States of Micronesia', abbreviation: 'FM' },
        { name: 'Florida', abbreviation: 'FL' },
        { name: 'Georgia', abbreviation: 'GA' },
        { name: 'Guam', abbreviation: 'GU' },
        { name: 'Hawaii', abbreviation: 'HI' },
        { name: 'Idaho', abbreviation: 'ID' },
        { name: 'Illinois', abbreviation: 'IL' },
        { name: 'Indiana', abbreviation: 'IN' },
        { name: 'Iowa', abbreviation: 'IA' },
        { name: 'Kansas', abbreviation: 'KS' },
        { name: 'Kentucky', abbreviation: 'KY' },
        { name: 'Louisiana', abbreviation: 'LA' },
        { name: 'Maine', abbreviation: 'ME' },
        { name: 'Marshall Islands', abbreviation: 'MH' },
        { name: 'Maryland', abbreviation: 'MD' },
        { name: 'Massachusetts', abbreviation: 'MA' },
        { name: 'Michigan', abbreviation: 'MI' },
        { name: 'Minnesota', abbreviation: 'MN' },
        { name: 'Mississippi', abbreviation: 'MS' },
        { name: 'Missouri', abbreviation: 'MO' },
        { name: 'Montana', abbreviation: 'MT' },
        { name: 'Nebraska', abbreviation: 'NE' },
        { name: 'Nevada', abbreviation: 'NV' },
        { name: 'New Hampshire', abbreviation: 'NH' },
        { name: 'New Jersey', abbreviation: 'NJ' },
        { name: 'New Mexico', abbreviation: 'NM' },
        { name: 'New York', abbreviation: 'NY' },
        { name: 'North Carolina', abbreviation: 'NC' },
        { name: 'North Dakota', abbreviation: 'ND' },
        { name: 'Northern Mariana Islands', abbreviation: 'MP' },
        { name: 'Ohio', abbreviation: 'OH' },
        { name: 'Oklahoma', abbreviation: 'OK' },
        { name: 'Oregon', abbreviation: 'OR' },
        { name: 'Palau', abbreviation: 'PW' },
        { name: 'Pennsylvania', abbreviation: 'PA' },
        { name: 'Puerto Rico', abbreviation: 'PR' },
        { name: 'Rhode Island', abbreviation: 'RI' },
        { name: 'South Carolina', abbreviation: 'SC' },
        { name: 'South Dakota', abbreviation: 'SD' },
        { name: 'Tennessee', abbreviation: 'TN' },
        { name: 'Texas', abbreviation: 'TX' },
        { name: 'Utah', abbreviation: 'UT' },
        { name: 'Vermont', abbreviation: 'VT' },
        { name: 'Virgin Islands', abbreviation: 'VI' },
        { name: 'Virginia', abbreviation: 'VA' },
        { name: 'Washington', abbreviation: 'WA' },
        { name: 'West Virginia', abbreviation: 'WV' },
        { name: 'Wisconsin', abbreviation: 'WI' },
        { name: 'Wyoming', abbreviation: 'WY' }
    ];
};

export const getCanadaProvinces = () => {
    return [
        { name: 'Alberta', abbreviation: 'AB', hst: 0.05 },
        { name: 'British Columbia', abbreviation: 'BC', hst: 0.12 },
        { name: 'Manitoba', abbreviation: 'MB', hst: 0.12 },
        { name: 'New Brunswick', abbreviation: 'NB', hst: 0.15 },
        { name: 'Newfoundland and Labrador', abbreviation: 'NL', hst: 0.15 },
        { name: 'Northwest Territories', abbreviation: 'NT', hst: 0.05 },
        { name: 'Nova Scotia', abbreviation: 'NS', hst: 0.15 },
        { name: 'Nunavut', abbreviation: 'NU', hst: 0.05 },
        { name: 'Ontario', abbreviation: 'ON', hst: 0.13 },
        { name: 'Prince Edward Island', abbreviation: 'PE', hst: 0.15 },
        { name: 'Quebec', abbreviation: 'QC', hst: 0.14975 },
        { name: 'Saskatchewan', abbreviation: 'SK', hst: 0.11 },
        { name: 'Yukon Territory', abbreviation: 'YT', hst: 0.05 }
    ];
};

export const cookiesBannerCountries = ['AT', 'BE', 'BG', 'HR', 'CY', 'CZ', 'DK', 'EE', 'FI', 'FR', 'DE', 'GR', 'HU', 'IS', 'IE', 'IT', 'LV', 'LI', 'LT', 'LU', 'MT', 'NL', 'NO', 'PL', 'PT', 'RO', 'SK', 'SI', 'ES', 'SE'];

export const isStaticAsset = (path) => /faq\/[a-zA-Z\d\s-]+\.svg/i.test(path);

export const getProductCategoryBaseRoutes = () => {
    return {
        'Currency': 'currency',
        'Items': 'items',
        'Accounts': 'accounts',
        'RS Questing': 'services',
        'Services': 'services',
        'RS Diaries': 'services',
        'RS PVM': 'services',
        'RS Minigames': 'services',
        'Subscription': 'customer portal/subscription',
        'Skins': 'skins',
        'Unique Names': 'unique-names',
        'Gift Cards': 'gifts',
        'Sell Currency': 'sell/currency',
        'Sell Item': 'sell/items',
        'Sell Items': 'sell/items',
        'Sell Account': 'sell/accounts',
        'Sell Accounts': 'sell/accounts',
        'Sell Skins': 'sell/skins',
        'Sell Unique Names': 'sell/unique-names',
        'Sell Gift Cards': 'sell/gift-cards'
    };
};

export const emailDisallowConRegExr = () => new RegExp(/[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.(?!con$)[a-zA-Z]{2,}/);

export const chargebackArrayToFilter = () => ['crypto', 'coinpayments', 'btcpay', 'bitcart'];

export const dateStringToSearch = () => new RegExp(/\b(\d{1,2})\/(\d{1,2})\/(\d{4})\b/);

export const paymentMethodListForShowingBlueLine = () => ['bluesnap-checkout', 'skrill-direct', 'crypto', 'coinpayments', 'google-pay-direct', 'game-currency', 'btcpay', 'bitcart', 'apple-pay-direct'];

export const paymentMethodListForShowingButton = () => ['bluesnap-checkout', 'skrill-direct', 'google-pay-direct', 'game-currency', 'apple-pay-direct'];

export const automaticPaymentMethods = () => ['bluesnap', 'paysafe', 'checkout', 'apple-pay-direct', 'google-pay-direct', 'solidgate', 'nmi', 'paymentlync'];

export const automaticPaymentMethodsWithout = () => ['bluesnap-checkout', 'google-pay-direct', 'apple-pay-direct'];

export const cardTypesFor3DSTrigger = () => ['visa', 'mastercard', 'amex', 'discover'];

export const automaticPaymentMethodsWithout3DS = () => ['paymentlync', 'solidgate', 'google-pay-direct', 'apple-pay-direct'];

export const letterNumberOrSymbol = /^[a-zA-Z0-9!@#$%^&*()_+-=,./?;:'"\[\]{}|\\<>~` ]$/;

export const htmlTagRegex = () => /<[^>]*>/g;

export const staticStateAndProvincesCountries = () => ['CA', 'US'];

export const staticProductCategories = () => ['Currency', 'Items', 'Accounts', 'Skins', 'Gift Cards', 'Unique Names'];

export const charactersAfAfterAnyDigitCheck = () => /\d+af/i;

export const staticProductNamesRegex = () => 'dynamic unique names|dynamic service|custom account|custom currency';

export const device__smallphone = 320;
export const device__smallphoneplus = 375;
export const device__mediumphone = 425;
export const device__phone = 576;
export const device__smalltablet = 720;
export const device__tablet = 768;
export const device__smalldesktop = 952;
export const device__desktop = 992;
export const device__laptop = 1024;
export const device__mediumdesktop = 1080;
export const device__widescreen = 1200;
export const device__widescreenplus = 1235;
export const device__mediumwidescreen = 1400;
export const device__laptoplarge = 1440;
export const device__extrascreen = 1500;
export const device__extrawidescreen = 1550;
export const device__extralargeplusscreen = 1920;
export const device__extralargescreen = 3839;

export const PAGE_NAMES = {
    HOME: 'Home',
    CURRENCY: 'Currency',
    CONTACT: 'Contact Us',
    BLOG: 'Blog',
    FAQ: 'Faq',
    ABOUT: 'About Us',
    PRIVACY_POLICY: 'Privacy Policy',
    TERMS_OF_SERVICE: 'Terms of Service',
    COPYRIGHT_POLICY: 'Copyright Policy',
    CART: 'Cart',
    CUSTOMER_PORTAL: 'Customer Portal',
    EXPIRED_LINK: 'Expired Link',
    ORDER_DETAILS: 'Order Details',
    BUG_BOUNTY: 'Bug Bounty',
    SITEMAP: 'Sitemap',
    SUBSCRIPTION: 'Subscription',
    BALANCE: 'Balance'
};

export default this;
