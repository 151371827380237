import { inject } from 'aurelia-framework';
import { ApiService } from './api-service';
import { websiteShortCode, getAWSBucketEndpoint, baseUrl } from 'environment';
import { CamelCaseValueConverter } from 'resources/value-converters/camel-case';
import { Helper } from 'resources/extensions/helper';
import numeral from 'numeral';
import { OrderTransactionType } from '../resources/helpers/enums'; // eslint-disable-line no-unused-vars

const ORDER_PATH = 'order_path';

@inject(ApiService, CamelCaseValueConverter, Helper)
export class OrderService {
    path = 'Order';
    orders;

    /**
     * @param {ApiService} api
     * @param {CamelCaseValueConverter} camelCaseValueConverter
     * @param {Helper} helper
     */
    constructor(api, camelCaseValueConverter, helper) {
        this.api = api;
        this.camelCaseValueConverter = camelCaseValueConverter;
        this.helper = helper;
        this.amazonProductBaseUrl = getAWSBucketEndpoint('products');
        this.amazonGameBaseUrl = getAWSBucketEndpoint('games');
    }

    async getAll() {
        if (this.orders) return this.orders;
        this.orders = await this.api.doGet(this.path + '?websiteShortCode=' + websiteShortCode());
        return this.orders;
    }

    /**
     * @param {number} recordQuantity
     * @param {number} page
     * @param {string | null} status
     * @param {string | null} search
     * @param {boolean} isBalance
     * @param {boolean | null} isSell
     * @param {OrderTransactionType | null} transactionType
     * @returns {any}
     */
    getAllWithPagination(recordQuantity, page, status = null, search = null, isBalance = false, isSell = false, transactionType = nulll) {
        status = status === 'All' || status === '' ? null : status;
        return this.api.doGet(this.helper.toParams((this.path + '/ListOrdersPagination'), { websiteShortCode: websiteShortCode(), recordQuantity, page, status, search, isBalance, isSell, transactionType }));
    }

    /**
     * Gets the user's orders listed by pagination
     * @param {number} recordQuantity Amount of orders to return
     * @param {number} page Current page the user is in
     * @param {string | null} status The status of the order to filter them by it
     * @param {string | null} search A query to use to filter out the orders
     * @param {boolean} isBalance Whether to return the orders from Balance or not
     * @param {boolean | null} isSell Whether to return the sold orders or not
     * @param {import('../resources/helpers/enums').OrderTransactionType | null} transactionType Whether it's a Purchased, Sold or a Swap order
     * @param {boolean} isCashback Whether to return the orders that contain Subscription Cashback or not
     * @returns {Promise<PurchasedOrder[]>} A promise with the list of orders filtered by current page
     */
    getCGListWithPagination(recordQuantity, page, status = null, search = null, isBalance = false, isSell = null, transactionType = null, isCashback = false, isReferral = false) {
        return this.api.doGet(this.helper.toParams((this.path + '/CGListOrdersPagination'), { websiteShortCode: websiteShortCode(), recordQuantity, page, status, search, isBalance, isSell, transactionType, isCashback, isReferral }));
    }

    /**
     * Gets the total count of orders from the user
     * @param {string | null} status The status of the order to filter them by it
     * @param {string | null} search A query to use to filter out the orders
     * @param {boolean} isBalance Whether to return the orders from Balance or not
     * @param {boolean | null} isSell Whether to return the sold orders or not
     * @param {import('../resources/helpers/enums').OrderTransactionType | null} transactionType Whether it's a Purchased, Sold or a Swap order
     * @param {boolean} isCashback Whether to return the orders that contain Subscription Cashback or not
     * @returns {Promise<number>} A promise with the total count of orders
     */
    getOrdersCountByWebsiteByUserId(status = null, search = null, isBalance = false, isSell = null, transactionType = null, isCashback = false, isReferral = false) {
        return this.api.doGet(this.helper.toParams((this.path + '/GetOrdersCountByWebsiteByUserId'), { websiteShortCode: websiteShortCode(), status, search, isBalance, isSell, transactionType, isCashback, isReferral }));
    }

    /**
     * Retrieves an order by its ID.
     *
     * @param {string} id - The unique identifier of the order.
     * @returns {Promise<PurchasedOrder>} A promise that resolves to the order data.
     */
    getById(id) {
        return this.api.doGet(`${this.path}/${id}`, { small: true, path: this.getOrderPath() });
    }

    /**
     * Gets the most recent order made by the user
     * @param {import('../resources/helpers/enums').OrderTransactionType | null} transactionType
     * @returns {Promise<RecentOrder>} A promise with the most recent order of the user
     */
    getMostRecentOrder(transactionType = null) {
        return this.api.doGet(this.helper.toParams(`${this.path}/GetMostRecentOrder`, { transactionType }));
    }

    async startOrder(data) {
        return this.api.doPost(this.path, data);
    }

    async resendReceipt(orderId) {
        return this.api.doPost(this.path + '/' + orderId + '/ResendReceipt');
    }

    async updateBluesnapCheckoutStatus(id, status, transactionId) {
        return this.api.doPost(`${this.path}/${id}/${status}/${transactionId}/updateBluesnapCheckoutStatus`);
    }

    async updateIdealStatus(id) {
        return this.api.doPost(`${this.path}/${id}/updateIdealStatus`);
    }

    async updateSofortStatus(id) {
        return this.api.doPost(`${this.path}/${id}/updateSofortStatus`);
    }

    async updateSkrillDirectStatus(id, status) {
        return this.api.doPost(`${this.path}/${id}/${status}/updateSkrillDirectStatus`);
    }

    async updateCheckoutStatus(id, status) {
        return this.api.doPost(`${this.path}/${id}/${status}/updateCheckoutStatus`);
    }

    async getLatestIdealOrder() {
        return this.api.doGet(this.path + '/GetLatestIdealOrder');
    }

    async createTopUpBalanceOrder(order) {
        return await this.api.doPost(`${this.path}/CreateTopUpBalanceOrder`, order);
    }

    async createSubscriptionOrder(order) {
        return await this.api.doPost(`${this.path}/CreateSubscriptionOrder`, order);
    }

    async createDynamicServiceOrUniqueNamesOrder(order) {
        return await this.api.doPost(`${this.path}/CreateDynamicServiceOrUniqueNamesOrder`, order);
    }

    async checkIfUserHasFulfilledOrders(quantity, fromAds, orderWebsiteShortCode) {
        return await this.api.doGet(`${this.path}/${quantity}/${fromAds}/checkIfUserHasFulfilledOrders${orderWebsiteShortCode ? `?orderWebsiteShortCode=${orderWebsiteShortCode}` : ''}`);
    }

    async checkIfFirstOrderFromAds() {
        return await this.api.doGet(`${this.path}/CheckIfFirstOrderFromAds`);
    }

    async getTotalOrderCountByGameAndProductCategory(gameId, productCategoryId) {
        return await this.api.doGet(`${this.path}/${gameId}/${productCategoryId}/TotalOrderCountByGameAndProductCategory?websiteShortCode=CG`);
    }

    /**
     * @param {CustomOrderRequest} data
     * @returns {Promise<CustomOrder | null>}
     */
    async startCustomOrder(data) {
        return await this.api.doPost(`${this.path}/CreateCustomOrder`, data);
    }

    async updateOrderStatus(orderId, status) {
        let response = await this.api.doPost(`${this.path}/${orderId}/${status}/UpdateOrderStatus`);
        if (response && typeof response !== 'string') {
            response = this.camelCaseValueConverter.toView(response);
        }
        return response;
    }

    async handleProductRatingSchema(game, productCategory, product, currency, overridedPrice, isSpecificProduct = false) {
        const currencyCategoryName = () => {
            let name = product?.game?.name;
            switch (product?.game?.shortName) {
                case 'POE':
                    name += ' Currency';
                    break;
                case 'FFXIV':
                    name += ' Gil';
                    break;
                case 'NW':
                    name += ' Coins';
                    break;
                case 'EFT':
                    name += ' Roubles';
                    break;
                default:
                    name += ' Gold';
                    break;
            }
            return name;
        };
        const totalOrderCount = await this.getTotalOrderCountByGameAndProductCategory(game.id, productCategory.id);
        let productSchema = `{
                            "@type": "Product",
                            "@id": "${baseUrl()}#/schema/Product/${product?.id ?? productCategory.id}",
                            "name": "${isSpecificProduct && productCategory.name !== 'Currency' ? product.name : isSpecificProduct && productCategory.name === 'Currency' ? currencyCategoryName() : `${game.name} ${productCategory.name}` ?? ''}",
                            "aggregateRating": {
                                "@id": "${baseUrl()}#/schema/Product/${product?.id ?? productCategory.id}/AggregateRating"
                            }`;
        const imagePath = this.validateImagePathConditions(product, productCategory, game);
        if (imagePath) productSchema += `,\n"image": "${productCategory.name === 'Services' ? this.amazonGameBaseUrl : this.amazonProductBaseUrl}${imagePath}"`;
        else {
            let defaultImagePath = 'icons/';
            switch (product?.productCategory?.name ?? productCategory?.name) {
                case 'Currency':
                    defaultImagePath += 'diamond.svg';
                    break;
                case 'Items':
                    defaultImagePath += 'sword-no-border.svg';
                    break;
                case 'Accounts':
                    defaultImagePath += 'icon_account.svg';
                    break;
                case 'Services':
                    defaultImagePath = 'home/circle-arrow.svg';
                    break;
                case 'Swap':
                    defaultImagePath = 'home/circle-swap.svg';
                    break;
                case 'Skins':
                    defaultImagePath += 'icon_skins.svg';
                    break;
                default:
                    defaultImagePath += 'chicks-crown.png';
                    break;
            }
            productSchema += `,\n"image": "${baseUrl()}${defaultImagePath}"`;
        }
        if (product?.description) productSchema += `,\n"description": "${(this.helper.sanitizeHtml(product.description, true)).replaceAll('"', '')}"`;
        productSchema += `,
                                    "brand": {
                                        "@id": "${baseUrl()}#/schema/Brand/ChicksGold"
                                    }
                                    `;
        if (isSpecificProduct) {
            productSchema += `,
                                        "offers": {
                                            "@id": "${baseUrl()}#/schema/Offer/1",
                                            "seller": {
                                                "@id": "${baseUrl()}#/schema/Organization/ChicksGold"
                                            }
                                        }
                                        `;
        }
        productSchema += '},';

        if (isSpecificProduct) {
            productSchema += `{
                                "@type": "Offer",
                                "@id": "${baseUrl()}#/schema/Offer/1",
                                "url": "${window.location.href}",
                                "priceCurrency": "${currency}",
                                "availability": "http://schema.org/InStock",
                                "price": ${productCategory.name === 'Currency' ? numeral(overridedPrice).format('0.00[000]') : numeral(overridedPrice).format('0.00')}
                            },`;
        }

        productSchema += `{
                            "@type": "AggregateRating",
                            "@id": "${baseUrl()}#/schema/Product/${product?.id ?? productCategory.id}/AggregateRating",
                            "ratingValue": "4.995",
                            "ratingCount": "${totalOrderCount > 0 ? totalOrderCount : 1}",
                            "reviewCount": "${totalOrderCount > 0 ? totalOrderCount : 1}",
                            "bestRating": "5",
                            "worstRating": "1"
                        },
                        {
                            "@type": "Brand",
                            "@id": "${baseUrl()}#/schema/Brand/ChicksGold",
                            "name": "Chicks Gold Inc."
                        }`;

        this.helper.combineApplicationLdJsonSchemasIntoOne(productSchema);
    }

    validateImagePathConditions = (product, productCategory, game) => {
        switch (productCategory.name) {
            case 'Currency':
                return product?.schemaImagePath ?? product?.imagePath;
            case 'Services':
                return game?.schemaImagePath ?? game?.gameNavigationIcon;
            default:
                return product?.imagePath;
        }
    };

    /**
     *
     * @param {number} orderId
     * @returns {Object} The new status of the order
     */
    async cancelOrder(orderId) {
        return await this.api.doPatch(`${this.path}/${orderId}/CancelOrder`);
    }

    /**
     *
     * @param {OrderTransactionType} transactionType
     * @returns {number} Total Price of all orders of said transaction type
     */
    async getCustomerTotalPriceOrders(transactionType) {
        return await this.api.doGet(`${this.path}/GetCustomerTotalPriceOrders`, { transactionType });
    }

    /**
     * Saves the given order path to local storage.
     *
     * @param {string} path - The path to be saved.
     * @returns {void}
     */
    saveOrderPath(path) {
        localStorage.setItem(ORDER_PATH, path);
    }

    /**
     * Retrieves the order path from the local storage.
     *
     * @returns {string|null} The order path if it exists in local storage, otherwise null.
     */
    getOrderPath() {
        return localStorage.getItem(ORDER_PATH);
    }

    /**
     * Removes the order path from the local storage.
     *
     * @returns {void}
     */
    removeOrderPath() {
        localStorage.removeItem(ORDER_PATH);
    }
}
