import environment from '../config/environment.json';

const websiteShortCode = () => environment.websiteShortCode;

const adminPanelUrl = () => environment.adminPanelUrl;

const apiEndpoint = () => environment.apiEndpoint;

const signalrEndpoint = () => environment.signalrEndpoint;

const apiPdfEndpoint = () => environment.apiPdfEndpoint;

const paysafeApiKey = () => environment.paysafeKey;

const paysafeMerchantId = () => environment.paysafeMerchantId;

const paysafeEnvironment = () => environment.paysafeEnvironment;

const paysafeIgnoreCc = () => environment.paysafeIgnoreCC;

const paysafeUsdAccount = () => environment.paysafeUSDAccount;

const paysafeCadAccount = () => environment.paysafeCADAccount;

const debug = () => environment.debug;

const blueSnapUrl = () => environment.blueSnapUrl;

const kountUrl = () => environment.kountUrl;

const countries = () => environment.countries;

const reCaptchaKey = () => environment.reCaptchaKey;

const getAWSBucketEndpoint = (endpoint) => environment.amazonAwsUrl.replace('dynamic', endpoint);

const checkoutPublicKey = () => environment.checkoutPublicKey;

const checkoutRiskBaseUrl = () => environment.checkoutRiskBaseUrl;

const baseUrl = () => environment.baseUrl;

const chicksXBaseUrl = () => environment.chicksXBaseUrl;

const accKingsBaseUrl = () => environment.accKingsBaseUrl;

const gamerTotalBaseUrl = () => environment.gamerTotalBaseUrl;

const divicaSalesBaseUrl = () => environment.divicaSalesBaseUrl;

const chicksGoldBlogsUrl = () => environment.chicksGoldBlogsUrl;

const firebaseConfig = () => environment.firebaseConfig;

const googlePayMerchantId = () => environment.googlePayMerchantId;

const discordAuthUrl = () => environment.discordAuthUrl;

const googleSignInClientId = () => environment.googleSignInClientId;

const discordSignInUrl = () => environment.discordSignInUrl;

const discordSignUpUrl = () => environment.discordSignUpUrl;

const applePayMerchantId = () => environment.applePayMerchantId;

const appleClientId = () => environment.appleClientId;

const environmentName = () => environment.environment;

const vgsInboundRouteId = () => environment.vgsInboundRouteId;

const vgsGooglePayInboundRouteId = () => environment.vgsGooglePayInboundRouteId;

const vgsApplePayInboundRouteId = () => environment.vgsApplePayInboundRouteId;

const vgsVaultId = () => environment.vgsVaultId;

const vgsEnv = () => environment.vgsEnv;

const vgsOrganizationId = () => environment.vgsOrganizationId;

const fingerprintApiKey = () => environment.fingerprintApiKey;

const fingerprintDomainUrl = () => environment.fingerprintDomainUrl;

const clientId = () => environment.clientId;

const chicksGroupBaseUrl = () => environment.chicksGroupBaseUrl;

const paypalClientId = () => environment.paypalClientId;

const paypalPlans = () => environment.paypalPlans;

const sardineAiClientId = () => environment.sardineAi.clientId;

const sardineAiEnvironment = () => environment.sardineAi.environment;

const sardineAiApiSubdomain = () => environment.sardineAi.apiSubdomain;

const sardineAiPixelSubdomain = () => environment.sardineAi.pixelSubdomain;

export default this;

export {
    websiteShortCode,
    adminPanelUrl,
    apiEndpoint,
    signalrEndpoint,
    apiPdfEndpoint,
    paysafeApiKey,
    paysafeMerchantId,
    reCaptchaKey,
    paysafeEnvironment,
    paysafeIgnoreCc,
    paysafeUsdAccount,
    paysafeCadAccount,
    kountUrl,
    blueSnapUrl,
    countries,
    getAWSBucketEndpoint,
    checkoutPublicKey,
    checkoutRiskBaseUrl,
    baseUrl,
    chicksXBaseUrl,
    accKingsBaseUrl,
    gamerTotalBaseUrl,
    divicaSalesBaseUrl,
    chicksGoldBlogsUrl,
    firebaseConfig,
    googlePayMerchantId,
    discordAuthUrl,
    googleSignInClientId,
    discordSignInUrl,
    discordSignUpUrl,
    applePayMerchantId,
    appleClientId,
    debug,
    environmentName,
    vgsInboundRouteId,
    vgsGooglePayInboundRouteId,
    vgsApplePayInboundRouteId,
    vgsVaultId,
    vgsEnv,
    vgsOrganizationId,
    fingerprintApiKey,
    fingerprintDomainUrl,
    clientId,
    chicksGroupBaseUrl,
    paypalClientId,
    paypalPlans,
    sardineAiClientId,
    sardineAiEnvironment,
    sardineAiApiSubdomain,
    sardineAiPixelSubdomain
};
